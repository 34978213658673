import React, { useEffect, useMemo, useState } from 'react'
import usePage from '../../hooks/page.hook'
import { useSelector } from 'react-redux'
import useWorkers from '../../hooks/workers.hook'
import * as workersSelectors from '../../redux/selectors/workers.selectors'
import * as filterSelectors from '../../redux/selectors/filter.selectors'

import styles from '../../styles/pages/admin/workers.module.css'
import Worker from '../../components/Admin/Worker'
import WorkerFilter from '../../components/Admin/WorkerFilter'


function Workers() {  
  const workersList = useSelector(workersSelectors.filter())
  const count = useMemo(() => workersList.length, [workersList])

  const { load } = useWorkers()

  const [workers, setWorkers] = useState([])
  const [isLoad, setIsLoad] = useState(false)

  const pagination = usePage(50, count)
  const page = pagination.page

  const triger = useSelector(filterSelectors.workerTriger)
  const workerTriger = useSelector(workersSelectors.triger)

  useEffect(() => { (async () => load())().then(() => setIsLoad(true)) }, [])

  useEffect(() => {
    const { begin, end } = pagination.getRange()

    setWorkers(workersList.slice(begin, end))
  }, [page, triger, isLoad, workerTriger])

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.top}>
          <WorkerFilter />
        </div>

        <div className={styles.info}>
            <div>Login</div>
            <div>ID</div>          
            <div>Priority IN</div>              
            <div>Priority OUT</div>                  
            <div className={styles.date}>Balance</div>
        </div>

        <div className={styles.table}>
            {workers.map((worker) => <Worker worker={worker} key={worker._id} />)}
        </div>

        <div className={styles.bottom}>
            <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                Previos
            </button>
            <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                Next
            </button>
        </div>
      </div>
    </div>
  )
}

export default Workers