import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useCardApi from '../API/card.api'
import useCards from '../hooks/cards.hook'
import useInput from '../hooks/input.hook'
import Input from '../components/UI/Input'
import useAlert from '../hooks/alert.hook'


function Card() {
    const { id } = useParams()

    const cardApi = useCardApi()
    const { refresh } = useCards()
    const { pushMess, pushError } = useAlert()

    const [card, setCard] = useState(null)

    const min = useInput('0')
    const max = useInput('0')
    const count = useInput('0')
    const total = useInput('0')
    const operator = useInput('')
    const hash = useInput('')

    const [isJar, setIsJar] = useState(false)
    const [bank, setBank] = useState(null)

    const monoLink = useInput('')


    useEffect(() => {
        const load = async () => { 
            const card = await cardApi.get(id)

            setCard(card) 
            min.changeValue(card?.limits?.min)
            max.changeValue(card?.limits?.max)
            count.changeValue(card?.limits?.count)
            total.changeValue(card?.limits?.total)
            operator.changeValue(card?.operator)
            hash.changeValue(card?.NPhash)


            setBank(card?.bank || '')
            setIsJar(card.isJar)
            //'https://send.monobank.ua/jar/'
            monoLink.changeValue(card?.jarHash)
        }
        load()
    }, [id])

    const resetHandler = async () => {
        const card = await cardApi.reset(id)

        setCard(card)
        refresh(card)

        pushMess('Count reset')
    }

    const resetTotalHandler = async () => {
        const card = await cardApi.resetTotal(id)

        setCard(card)
        refresh(card)

        pushMess('Total reset')
    }

    const saveHandler = async () => {
        const card = await cardApi.setLimits(id, min.value, max.value, count.value, total.value)
        if(!card) { return }

        setCard(card)
        refresh(card)

        pushMess('Save Successeful')
    }

    const operatorHandler = async () => {
        const card = await cardApi.setOperator(id, operator.value)

        setCard(card)
        refresh(card)

        pushMess('Save Successeful')
    }

    const jarHandler = async () => {
        // const index = monoLink.value.indexOf('jar/')
        // if(index === -1) { return pushError('invalid card') }
        // const hash = monoLink.value.substring(index + 4)

        const hash = monoLink.value

        const card = await cardApi.setJar(id, hash)

        setCard(card)
        refresh(card)

        pushMess('Save Successeful')
    }

    
    const hashHandler = async () => {
        const card = await cardApi.setNPHash(id, hash.value)
        if(!card) { return }

        setCard(card)
        refresh(card)

        pushMess('Save Successeful')
    }

    const setBankHandler = (value) => {
        setBank((prew) => {
            if(prew === value) { return '' }
            return value
        })
    }

    const bankHandler = async () => {
        const card = await cardApi.setBank(id, bank)
        if(card) { pushMess('Success') }
    }

    return (
        <div className='b-scroll'>
            <div>Id: {card?._id}</div>
            <div>Card: {card?.number}</div>
            <div>Partner: {card?.partnerName || card?.partner}</div>
            <div>Count: {card?.count}</div>
            <div>Total: {card?.total}</div>
            <br />

            <button className='button' onClick={() => resetHandler()}>Reset count</button>
            <button className='button' onClick={() => resetTotalHandler()}>Reset total</button>
            <br />
            <br />

            <div className='d-flex'>
                <input type='checkbox' checked={isJar} onChange={() => setIsJar((prev) => !prev)}/>
                <div>Is Jar</div>
            </div>
            <div className='d-flex'>
                <input type='checkbox' checked={card?.isIban}/>
                <div>Is Iban</div>
            </div>
            <div className='d-flex'>
                <input type='checkbox' checked={card?.npacq}/>
                <div>Is NPay</div>
            </div>
            <br />
            {isJar && <>
                <Input input={monoLink} placeholder='Momo link' />
                <br />
                <br />
                <button className='button' onClick={() => jarHandler()}>Save</button>
                <br />
            </>}
            {card?.npacq && <>
                <Input input={hash} placeholder='NPHash' />
                <br />
                <br />
                <button className='button' onClick={() => hashHandler()}>Save</button>
                <br />
            </>}
            <br />

            <div className="d-flex">
                <input type='radio' checked={(bank === '')} onChange={() => setBankHandler('')}/>
                <div>None</div>
            </div>
            <div className="d-flex">
                <input type='radio' checked={(bank === 'privat24')} onChange={() => setBankHandler('privat24')}/>
                <div>Privat24</div>
            </div>
            <div className="d-flex">
                <input type='radio' checked={(bank === 'mono')} onChange={() => setBankHandler('mono')}/>
                <div>Mono Bank</div>
            </div>
            
            <br />
            <button className='button' onClick={() => bankHandler()}>Save</button>
            <br />
            <br />

            <h3>limits</h3>
            <br />
            <Input input={min} placeholder='Min limit'/>
            <Input input={max} placeholder='Max limit'/>
            <Input input={count} placeholder='Count limit'/>
            <Input input={total} placeholder='Total limit'/>
            <br />
            <br />
            <button className='button' onClick={() => saveHandler()}>Save</button>
            <br />
            <br />
            <br />
            <h3>Operator</h3>
            <br />
            <Input input={operator} placeholder='@operator'/>
            <br />
            <br />
            <button className='button' onClick={() => operatorHandler()}>Save</button>
        </div>
    )
}

export default Card